// store/modules/zdInput.js
import Vue from 'vue'; // 确保引入 Vue
// 定义模块的状态对象，用于存储组件的数据
const state = {
  components: {}, // 存储所有组件的数据
};

// 定义模块的 mutation 函数，用于修改状态
const mutations = {
  // 修改 INIT_COMPONENT 以每次都设置组件数据
  INIT_COMPONENT(state, { id, data }) {
    Vue.set(state.components, id, data || {});
  },
  UPDATE_COMPONENT(state, { id, field, value }) {
    if (state.components[id]) {
      Vue.set(state.components[id], field, value);
    }
  },
  SET_COMPONENTS(state, components) {
    state.components = { ...state.components, ...components };
  },
  RESET_COMPONENTS(state) {
    state.components = {};
  },
};


// 定义模块的 action 函数，用于触发 mutation
const actions = {
  initializeComponent({ commit }, payload) {
    commit('INIT_COMPONENT', payload);
  },
  updateComponent({ commit }, payload) {
    commit('UPDATE_COMPONENT', payload);
  },
  setComponents({ commit }, payload) {
    commit('SET_COMPONENTS', payload);
  },
  resetComponents({ commit }) {
    commit('RESET_COMPONENTS');
  },
};


// 定义模块的 getter 函数，用于获取状态
const getters = {
  // 获取指定组件的状态
  getComponentState: (state) => (id) => {
    return state.components[id] || {}; // 返回指定组件的数据，如果不存在则返回空对象
  },
  getAllComponents: (state) => {
    return state.components;
  },
};

// 导出模块配置
export default {
  namespaced: true, // 开启命名空间
  state,
  mutations,
  actions,
  getters,
};
