let baseUrl = process.env.VUE_APP_API_BASE_URL

// 英文 spec
export const newEnSpec = {
  verifyUrl: baseUrl + 'api/newSpec/verify',
  addVerifyListUrl: baseUrl + 'api/newSpec/addVerifyList',
  itemCodeListUrl: baseUrl + 'api/newSpec/getItemCodeList',
  pageListUrl: baseUrl + 'api/newSpec/getPageList',
  infoUrl: baseUrl + 'api/newSpec/info',
  infoByItemCodeUrl: baseUrl + 'api/newSpec/infoByItemCode',
  auditedListUrl: baseUrl + 'api/newSpecAudited/auditedList',
  auditedLatestInfoUrl: baseUrl + 'api/newSpecAudited/latestInfo',
  addUrl: baseUrl + 'api/newSpec/add',
  updateUrl: baseUrl + 'api/newSpec/update',
  saveOrUpdateUrl: baseUrl + 'api/newSpec/saveOrUpdate',
  deleteUrl: baseUrl + 'api/newSpec/delete',
}
// 中文 spec
export const newCnSpec = {
  itemCodeListUrl: baseUrl + 'api/newCnSpec/getItemCodeList',
  addVerifyListUrl: baseUrl + 'api/newCnSpec/addVerifyList',
  addUrl: baseUrl + 'api/newCnSpec/add',
  updateUrl: baseUrl + 'api/newCnSpec/update',
  saveOrUpdateUrl: baseUrl + 'api/newCnSpec/saveOrUpdate',
  pageListUrl: baseUrl + 'api/newCnSpec/getPageList',
  infoUrl: baseUrl + 'api/newCnSpec/info',
  infoByItemCodeUrl: baseUrl + 'api/newCnSpec/infoByItemCode',
  auditedListUrl: baseUrl + 'api/newCnSpecAudited/auditedList',
  verifyUrl: baseUrl + 'api/newCnSpec/verify',
  deleteUrl: baseUrl + 'api/newCnSpec/delete',
}
// 英文 sds
export const newEnSds = {
  pageListUrl: baseUrl + 'api/newSds/getPageList',
  addUrl: baseUrl + 'api/newSds/add',
  updateUrl: baseUrl + 'api/newSds/update',
  saveOrUpdateUrl: baseUrl + 'api/newSds/saveOrUpdate',
  infoUrl: baseUrl + 'api/newSds/info',
  deleteUrl: baseUrl + 'api/newSds/delete',
  verifyUrl: baseUrl + 'api/newSds/verify',
  addVerifyListUrl: baseUrl + 'api/newSds/addVerifyList',
  copyUrl: baseUrl + 'api/newSds/copy',
  matchUrl: baseUrl + 'api/newSds/match',
  itemCodeList: baseUrl + 'api/newSds/getItemCodeList',
  auditedListUrl: baseUrl + 'api/newSdsAudited/auditedList',
  auditedLatestInfoUrl: baseUrl + 'api/newSdsAudited/latestInfo',
}
// 中文 sds
export const newCnSds = {
  itemCodeListUrl: baseUrl + 'api/newCnSds/getItemCodeList',
  pageListUrl: baseUrl + 'api/newCnSds/getPageList',
  saveOrUpdateUrl: baseUrl + 'api/newCnSds/saveOrUpdate',
  auditedLatestInfoUrl: baseUrl + 'api/newCnSdsAudited/latestInfo',
  addUrl: baseUrl + 'api/newCnSds/add',
  updateUrl: baseUrl + 'api/newCnSds/update',
  infoUrl: baseUrl + 'api/newCnSds/info',
  auditedListUrl: baseUrl + 'api/newCnSdsAudited/auditedList',
  verifyUrl: baseUrl + 'api/newCnSds/verify',
  addVerifyListUrl: baseUrl + 'api/newCnSds/addVerifyList',
  deleteUrl: baseUrl + 'api/newCnSds/delete',
}

// 英文 coa
export const newEnCoa = {
  pageListUrl: baseUrl + 'api/newCoa/getPageList',
  infoUrl: baseUrl + 'api/newCoa/info',
  addUrl: baseUrl + 'api/newCoa/add',
  updateUrl: baseUrl + 'api/newCoa/update',
  saveOrUpdateUrl: baseUrl + 'api/newCoa/saveOrUpdate',
  deleteUrl: baseUrl + 'api/newCoa/delete',
  verifyUrl: baseUrl + 'api/newCoa/verify',
  suggestionsList: baseUrl + 'api/newCoa/getSuggestions'
}

// 中文 coa
export const newCnCoa = {
  pageListUrl: baseUrl + 'api/newCnCoa/getPageList',
  infoUrl: baseUrl + 'api/newCnCoa/info',
  addUrl: baseUrl + 'api/newCnCoa/add',
  updateUrl: baseUrl + 'api/newCnCoa/update',
  saveOrUpdateUrl: baseUrl + 'api/newCnCoa/saveOrUpdate',
  deleteUrl: baseUrl + 'api/newCnCoa/delete',
  verifyUrl: baseUrl + 'api/newCnCoa/verify',
  suggestionsList: baseUrl + 'api/newCoa/getSuggestions'
}

export const userManager = {
  userList: baseUrl + 'api/sysUser/getPageList',
  userAdd: baseUrl + 'api/sysUser/add',
  userUpdate: baseUrl + 'api/sysUser/update',
  userRemove: baseUrl + 'api/sysUser/delete',
  resetUserPassword: baseUrl + 'api/sysUser/resetPassword'
}
